import { REGEX } from 'utils/regex'

/**
 * @description
 *
 * Функция возвращает объект для валидации поля name/surname/patronymic
 *
 * @return { Object } - объект функций с валидацией поля по различным параметрам. Каждая функция принимает обязательное значение поля - value
 * */

const MAX_LENGTH = 30 // TODO: добавить получение значения с контекста бэкенда (сейчас не передаётся)

const REQUIRED_DESCRIPTION = {
  name: 'Укажите имя',
  surname: 'Укажите фамилию',
  patronymic: 'Заполните поле или отметьте, что отчества нет',
}

const getFIOValidation = ({ type = '' }) => ({
  required: val => !!val || REQUIRED_DESCRIPTION[type],
  onlyRu: val => REGEX.fio.test(val) || 'Используйте только кириллицу',
  maxLength: val => val.length <= MAX_LENGTH || `Должно быть не больше ${MAX_LENGTH} символов`,
})

export default getFIOValidation
