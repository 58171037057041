import { addDays } from 'date-fns'

/**
 * @description Проверяет доступность слота по расписанию конкретного дня работы клиники
 *
 * @param { object } params
 * @param { string } params.slotDay - день слота
 * @param { Date } params.slotDate - дата слота
 * @param { LpuScheduleDay } params.scheduleDay - расписание дня работы клиники
 *
 * @return { boolean }
 * */
function checkAvailableSlotByScheduleDay({ slotDay, slotDate, scheduleDay }) {
  const {
    timeStart,
    timeEnd,
  } = scheduleDay

  const dateStart = new Date(`${slotDay}T${timeStart}`)
  let dateEnd = new Date(`${slotDay}T${timeEnd}`)

  if (dateStart > dateEnd) {
    dateEnd = addDays(dateEnd, 1)
  }

  return dateStart <= slotDate && slotDate <= dateEnd
}

/**
 * @description Проверяет доступность слота по расписанию работы клиники
 *
 * @param { object } params
 * @param { string } params.slotDay - день слота
 * @param { string } params.slotPrevDay - предыдущий день слота
 * @param { Date } params.slotDate - дата слота
 * @param { Record<string, LpuScheduleDay> } params.schedule - расписание работы клиники
 *
 * @return { boolean }
 * */
function checkAvailableSlotBySchedule({
  slotDay,
  slotPrevDay,
  slotDate,
  schedule,
}) {
  /**
   * Необходимо проверить расписание предыдущего дня, т.к. оно может заканчиваться в текущий день.
   * Например, если график работы такой — `18:00-04:00` (отличается от `04:00-18:00`).
   * */
  const scheduleDay = schedule[slotDay]
  const schedulePrevDay = schedule[slotPrevDay]

  if (!scheduleDay && !schedulePrevDay) {
    return false
  }

  let isAvailableSlotByScheduleDay = false

  if (scheduleDay) {
    isAvailableSlotByScheduleDay = checkAvailableSlotByScheduleDay({
      slotDay,
      slotDate,
      scheduleDay,
    })
  }

  if (!isAvailableSlotByScheduleDay && schedulePrevDay) {
    return checkAvailableSlotByScheduleDay({
      slotDay: slotPrevDay,
      slotDate,
      scheduleDay: schedulePrevDay,
    })
  }

  return isAvailableSlotByScheduleDay
}

export default checkAvailableSlotBySchedule
