<template>
  <div data-qa="user_authorization">
    <UserAuthorizationForm
      ref="authorization-form"
      :fn-handler-submit-form="handleGetCode"
      :hook-before-submit-form="hookBeforeSubmitForm"
      :is-phone-confirmed="isPhoneConfirmed"
      :is-phone-readonly="isPhoneReadonly"
      :is-autofocus-field="isRenderInModal"
      :is-allowed-submit="!isDisabledButton"
      :is-blocking-error="isBlockingError"
      :init-phone="initPhone"
      :error-message="requestGetCodeData.errorMessage"
      :is-country-authorization="isCountryAuthorization"
      :is-authorization-vk="isAuthorizationVk"
      is-default-slot-always-visible
      @form-authorization:click-edit="handleClickEditPhone"
      @form-authorization:input="handleInputEditPhone"
    >
      <template #default="{ isActiveSlot, isCodeInputVisible }">
        <UserAuthorizationCheckCode
          v-if="isVisibleCheckCode || isAuthorizationVk"
          ref="user-authorization-check-code"
          :phone="requestGetCodeData.phone"
          :is-autofocus-field="isActiveSlot"
          :confirmation-methods="confirmationMethods"
          :is-no-registration="isNoRegistration"
          :service-id="requestGetCodeData.serviceId"
          :is-code-input-visible="isVisibleCheckCode && isCodeInputVisible || !isAuthorizationVk"
          data-qa="user_authorization_code_confirmation_help"
          @authorization-check-code:success="handleSuccessCheckCode"
        />
      </template>
      <template #action="{ onSubmitForm, loading, disabled }">
        <VExpandTransition>
          <div v-if="isVisibleErrorRequestExceeded">
            <div
              class="ui-text ui-text_body-2 ui-kit-color-text-secondary text-center mb-2"
              data-qa="user_authorization_code_exceeded_request_error"
            >
              Подтвердить этот номер можно через сутки.<br>
              Было слишком много попыток
            </div>
          </div>
          <div v-else-if="requestGetCodeData.isDeletingInProgress">
            <BaseAlert
              type="red"
              icon="ui-icon-circle-warning"
              data-qa="user_authorization_code_deleting_in_progress_error"
            >
              <div class="ui-text ui-text_body-2">
                С этого номера пока нельзя записаться на приём или оставить отзыв:
                старый профиль ещё на удалении, это займёт до&nbsp;30 дней.
              </div>
            </BaseAlert>
          </div>
        </VExpandTransition>

        <div :style="styleForButton">
          <VExpandTransition>
            <VBtn
              v-if="isVisibleButton"
              class="pa-2"
              block
              depressed
              color="primary"
              :text="!isVisibleDefaultButton"
              :loading="loading"
              :disabled="extendDisabledForTimer(disabled)"
              data-qa="user_authorization_confirmation_btn"
              @click="handleClickConfirm(onSubmitForm)"
            >
              {{ buttonText }}&nbsp;<span
                :id="expireTime.mountElementId"
                :hidden="!isVisibleExpireTime(disabled)"
              />
            </VBtn>
          </VExpandTransition>
          <VExpandTransition>
            <VBtn
              v-if="isSwitchTypeButtonVisible"
              class="mt-2"
              data-qa="user_authorization_switch_type"
              color="primary"
              :disabled="extendDisabledForTimer(disabled)"
              depressed
              block
              text
              @click="handleClickSwitchType(onSubmitForm)"
            >
              {{ CONFIRMATION_BUTTON_STATUSES.callOrSmsConfirm }}
            </VBtn>
          </VExpandTransition>
        </div>
      </template>
    </UserAuthorizationForm>
  </div>
</template>

<script>
import BaseAlert from 'components/common/core/BaseAlert'
import UserAuthorizationForm from 'components/common/UserAuthorization/components/common/UserAuthorizationForm'
import UserAuthorizationCheckCode from 'components/common/UserAuthorization/components/common/UserAuthorizationCheckCode'
import {
  authByCallOrSmsGetCodeMixin,
  authByCallOrSmsExpireTimeMixin,
} from 'components/common/UserAuthorization/mixins'
import {
  CONFIRMATION_BUTTON_STATUSES,
} from 'components/common/UserAuthorization/constants'
import { sendYaGoal } from 'components/common/UserAuthorization/functions'
import { AUTHORIZATION_VK_GOALS } from 'yandexGoals'

export default {
  name: 'UserAuthorizationByCallOrSms',
  components: {
    BaseAlert,
    UserAuthorizationForm,
    UserAuthorizationCheckCode,
  },
  mixins: [
    authByCallOrSmsGetCodeMixin,
    authByCallOrSmsExpireTimeMixin,
  ],
  props: {
    initPhone: {
      type: String,
      default: '',
    },
    isRenderInModal: {
      type: Boolean,
      default: false,
    },
    isPhoneReadonly: {
      type: Boolean,
      default: false,
    },
    hookBeforeSubmitForm: {
      type: Function,
      default: () => true,
    },
    isCountryAuthorization: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    CONFIRMATION_BUTTON_STATUSES,
    isPhoneEditedValid: false,
    isPhoneConfirmed: false,
  }),
  computed: {
    isAuthorizationVk() {
      return !this.isNoRegistration
    },
    buttonText() {
      if (this.requestGetCodeData.isWaitingTime) {
        return CONFIRMATION_BUTTON_STATUSES.pleaseWait
      }

      if (this.confirmationMethods.isVk && !this.requestGetCodeData.isVkIgnored) {
        if (this.isVisibleDefaultButton) {
          return CONFIRMATION_BUTTON_STATUSES.vkConfirm
        }

        return CONFIRMATION_BUTTON_STATUSES[this.requestGetCodeData.isAttemptsExceeded ? 'callConfirm' : 'vkConfirmAgain']
      }

      if (this.isVisibleDefaultButton) {
        return CONFIRMATION_BUTTON_STATUSES.confirm
      }

      if (this.confirmationMethods.isCall) {
        return CONFIRMATION_BUTTON_STATUSES[this.requestGetCodeData.isAttemptsExceeded ? 'smsConfirm' : 'callConfirmAgain']
      }

      return CONFIRMATION_BUTTON_STATUSES.smsConfirmAgain
    },
    styleForButton() {
      // Фикс проблемы с анимацией при сворачивании/разворачивании контента + кнопки
      return this.isVisibleButton ? null : { opacity: 0 }
    },
    isVisibleButton() {
      // Если нет определённых ошибок
      return !(this.isVisibleErrorRequestExceeded
        || this.requestGetCodeData.isDeletingInProgress)
    },
    isVisibleDefaultButton() {
      // Если кнопка ещё не нажималась или телефон был отредактирован
      return !this.requestGetCodeData.isRequested || this.isPhoneEditedValid
    },
    isDisabledButton() {
      // Если запущен таймер и при этом телефон не был отредактирован
      return this.expireTime.isRunning && !this.isPhoneEditedValid
    },
    isVisibleErrorRequestExceeded() {
      return this.requestGetCodeData.isRequestExceeded && !this.isPhoneEditedValid
    },
    isVisibleCheckCode() {
      return !this.isVisibleErrorRequestExceeded || !!this.requestGetCodeData.serviceId
    },
    isSwitchTypeButtonVisible() {
      return this.isVisibleButton
        && this.isAuthorizationVk
        && this.confirmationMethods.isVk
        && !this.requestGetCodeData.isVkIgnored
        && !this.requestGetCodeData.isAttemptsExceeded
    },
  },
  mounted() {
    if (this.initPhone) {
      this.handlePhoneConfirmed({ phone: this.initPhone })
    }
  },
  methods: {
    extendDisabledForTimer(isRootDisabledAction) {
      // Дополнительно деактивируем кнопку с учётом таймера
      return isRootDisabledAction || this.isDisabledButton
    },
    isVisibleExpireTime(isRootDisabledAction) {
      // Отображаем таймер когда он запущен и кнопка неактивна
      return this.extendDisabledForTimer(isRootDisabledAction) && this.expireTime.isRunning
    },
    handleInputEditPhone(isEditedValid) {
      this.requestGetCodeData.errorMessage = ''

      // Если true, значит телефон изменился при редактировании (после нажатия на `карандаш`)
      this.isPhoneEditedValid = isEditedValid

      if (this.isAuthorizationVk && isEditedValid) {
        this.requestGetCodeData.isVkIgnored = false
        this.requestGetCodeData.isAttemptsExceeded = false
        this.confirmationMethods.isVk = true
      }
    },
    handleClickEditPhone() {
      if (this.requestGetCodeData.isWaitingTime) {
        this.resetTimer()
      }

      this.resetBlockingErrors()
    },
    handleErrorExpireTime(waitingTime) {
      this.expireTime.waitingTime = waitingTime

      this.initTimer()

      this.requestGetCodeData.isWaitingTime = true
      this.requestGetCodeData.isRequested = true
    },
    handlePhoneConfirmed({ phone }) {
      this.isPhoneConfirmed = true
      this.$emit('authorization:confirmed', phone)
    },
    handleSuccessCheckCode({ phone }) {
      if (this.confirmationMethods.isVk) {
        sendYaGoal(AUTHORIZATION_VK_GOALS.vkCodeInputSuccess)
      } else if (this.confirmationMethods.isCall) {
        sendYaGoal(AUTHORIZATION_VK_GOALS.callCodeInputSuccess)
      }

      this.handlePhoneConfirmed({ phone })
      this.$emit('authorization:just-confirmed', this.confirmationMethods)
    },
    handleClickConfirm(onSubmitForm) {
      if (this.confirmationMethods.isVk && !this.requestGetCodeData.isAttemptsExceeded) {
        sendYaGoal(AUTHORIZATION_VK_GOALS.getCodeVk)
      } else if (this.confirmationMethods.isVk && this.requestGetCodeData.isAttemptsExceeded) {
        sendYaGoal(AUTHORIZATION_VK_GOALS.switchTypeAfterProblemVk)
      } else if (this.confirmationMethods.isCall && this.requestGetCodeData.isAttemptsExceeded) {
        sendYaGoal(AUTHORIZATION_VK_GOALS.switchTypeAfterProblemCall)
      }

      onSubmitForm()
    },
    handleClickSwitchType(onSubmitForm) {
      this.requestGetCodeData.isVkIgnored = true
      sendYaGoal(AUTHORIZATION_VK_GOALS.switchType)
      onSubmitForm()
    },
  },
}
</script>
