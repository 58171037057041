const regexLatinEWithDiaeresis = {
  upper: /Ë/g, // Латинская строчная буква "E" с диэризисом. Символ \00CB
  lower: /ë/g, // Латинская строчная буква "e" с диэризисом. Символ \00EB
}

/**
 * @description Возвращает строку, в которой заменена латинская строчная буква "e" с диэризисом
 *
 * @param { string } str
 * */
const getReplacedLatinEWithDiaeresis = str => str.replace(regexLatinEWithDiaeresis.upper, 'Ё')
  .replace(regexLatinEWithDiaeresis.lower, 'ё')

export default getReplacedLatinEWithDiaeresis
