import { declensionByNum } from 'utils'

function getRemainingTimeMessage(remainingTime) {
  const seconds = remainingTime % 60
  const secondsText = `${seconds} ${declensionByNum(seconds, ['секунду', 'секунды', 'секунд'])}`

  const minutes = (remainingTime - seconds) / 60
  const minutesText = `${minutes} ${declensionByNum(minutes, ['минуту', 'минуты', 'минут'])}`

  return `Повторно записаться можно через ${minutes ? minutesText : ''} ${secondsText}`
}

export default getRemainingTimeMessage
