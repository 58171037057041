import {
  differenceInMonths,
  differenceInYears,
  format,
  parse,
} from 'date-fns'
import { ru } from 'date-fns/locale'
import { declensionByNum } from 'utils'
import { MONTH_CASES, YEAR_CASES } from 'constants/wordCases'

/**
 * @typedef PatientData
 * @property { string } surname
 * @property { string } name
 * @property { string } patronymic
 * @property { Date | string | null } birthDay
 * */

/**
 * @typedef PartialPatientData
 * @property { string } fio
 * @property { string } age
 * @property { string } birthDay
 * */

/**
 * @description Возвращает отформатированный возраст пациента - в годах или месяцах
 * @param { PatientData } patientData
 * @param { Date | string | null } patientData.birthDay - дата рождения пациента
 * @param { boolean } isYearOnly - получить только год пациента
 *
 * @return { number | string | '' } - Отформатированный возраст пациента - в годах или месяцах, либо пустая строка.
 * Если поле "isYearOnly = true" - вернется число(0 или возраст пациента в годах).
 * Если поле "isYearOnly = false" - вернется форматированная или пустая строка.
 * */
const getPatientAge = ({ birthDay }, isYearOnly = false) => {
  if (!birthDay) {
    return isYearOnly ? 0 : ''
  }

  const now = new Date()
  const birthDayDate = typeof birthDay === 'string'
    ? parse(birthDay, 'dd.MM.yyyy', now, { locale: ru })
    : birthDay
  const ageYears = differenceInYears(now, birthDayDate)

  if (ageYears >= 1) {
    return isYearOnly ? ageYears : `${ageYears} ${declensionByNum(ageYears, YEAR_CASES.nominative)}`
  }

  if (isYearOnly) {
    return 0
  }

  const ageMonths = differenceInMonths(now, birthDayDate)

  return ageMonths
    ? `${ageMonths} ${declensionByNum(ageMonths, MONTH_CASES.nominative)}`
    : ''
}

const getPatientFio = ({ surname, name, patronymic }) => `${surname} ${name} ${patronymic}`.trim()

/**
 * @description Возвращает форматированный возраст, ФИО и день рождения пациента
 * @param { PatientData } patientData
 *
 * @return PartialPatientData
 * */
const getPartialPatientData = patientData => {
  const age = getPatientAge(patientData)
  const ageNumber = getPatientAge(patientData, true)
  const fio = getPatientFio(patientData)
  let birthDay = ''

  if (patientData.birthDay) {
    birthDay = typeof patientData.birthDay === 'string'
      ? patientData.birthDay
      : format(patientData.birthDay, 'dd.MM.yyyy', { locale: ru })
  }

  return {
    age,
    fio,
    ageNumber,
    birthDay,
  }
}

export default getPartialPatientData
