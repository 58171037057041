const DEFAULT_PATIENTS_DATA = {
  main: {
    id: -1,
    isMain: true,
    isOther: false,
    fio: 'Я',
  },
  other: {
    id: -2,
    isMain: false,
    isOther: true,
    fio: 'Другой человек',
  },
}

const MAX_FIO_LENGTH = 30

const MAX_PATIENT_AGE = 150

const REQUIRED_FIO_DESCRIPTION = {
  name: 'Укажите имя',
  surname: 'Укажите фамилию',
  patronymic: 'Заполните поле или отметьте, что отчества нет',
}

const FAMILY_PROFILES_SESSION_STORAGE_KEY = '__pd_AppointmentPageFamilyProfiles'

export {
  DEFAULT_PATIENTS_DATA,
  MAX_FIO_LENGTH,
  MAX_PATIENT_AGE,
  REQUIRED_FIO_DESCRIPTION,
  FAMILY_PROFILES_SESSION_STORAGE_KEY,
}
