import { declensionByNum } from 'utils'
import { YEAR_CASES } from 'constants/wordCases'

const isCorrectAge = age => Boolean(typeof age === 'number' && age)
const getAgeCaseText = age => `${age}\u00A0${declensionByNum(age, YEAR_CASES.genitive)}`

function getSpecialityAgesText({ min, max }, { withBrackets = true } = {}) {
  const ageLimitsStrings = []

  if (isCorrectAge(min)) {
    ageLimitsStrings.push(`от\u00A0${getAgeCaseText(min)}`)
  }

  if (isCorrectAge(max)) {
    ageLimitsStrings.push(`до\u00A0${getAgeCaseText(max)}`)
  }

  if (!ageLimitsStrings.length) {
    return ''
  }

  return withBrackets
    ? ` (${ageLimitsStrings.join(' ')})`
    : ` ${ageLimitsStrings.join(' ')}`
}

export default getSpecialityAgesText
