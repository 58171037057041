<template>
  <v-app
    data-side-menu
    class="app-side-menu"
    :class="{ 'app-side-menu_active': isActive || !isActive && !isTransitionEnd }"
  >
    <div>
      <v-navigation-drawer
        v-model="isActive"
        width="270"
        fixed
        @transitionend="handleTransitionend"
      >
        <v-list>
          <v-list-item
            :href="townSelectUrl"
            data-qa="active_town"
          >
            <v-list-item-icon>
              <v-icon class="ui-icon-map-pin" />
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title
                v-if="town"
                class="uiKitText--text"
              >
                {{ town }}
              </v-list-item-title>
              <v-list-item-title
                v-else
                class="uiKitTextInfo--text"
              >
                Город не указан
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-divider
            v-if="isRealPatient || isAnonymous"
            inset
          />

          <v-list-item
            v-if="isRealPatient"
            :href="getMedtochkaSectionUrl(medtochkaRoutes.profile)"
          >
            <v-list-item-icon>
              <v-icon class="ui-icon-user-only" />
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title class="uiKitText--text">
                Профиль
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item
            v-else-if="!isAnonymous"
            :href="personalAreaUrl"
          >
            <v-list-item-icon>
              <v-icon class="ui-icon-doctor" />
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title class="uiKitText--text">
                Личный кабинет
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <template v-else>
            <v-list-item
              data-qa="patient_login"
              @click.prevent="loginRelocation(loginPathMedtochka)"
            >
              <v-list-item-icon>
                <v-icon class="ui-icon-user-only" />
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title class="uiKitText--text">
                  Войти как пациент
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item
              data-qa="clinic_login"
              @click.prevent="loginRelocation(loginPathCabinet)"
            >
              <v-list-item-icon>
                <v-icon class="ui-icon-doctor" />
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title class="uiKitText--text">
                  Войти как врач/клиника
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>

          <template v-if="isRealPatient">
            <v-list-item
              v-if="clubData.isInClub"
              @click="handleClickCardInfo"
            >
              <v-list-item-icon class="my-auto">
                <v-img
                  src="/static/_v1/pd/icons/ui-kit/club/club-check.svg"
                  width="24"
                  height="24"
                  alt="Иконка Галочка Клуба"
                  eager
                />
              </v-list-item-icon>
              <v-list-item-content class="py-2">
                <v-list-item-title class="uiKitText--text">
                  Клубная карта
                </v-list-item-title>
                <v-list-item-subtitle class="uiKitTextInfo--text">
                  до {{ clubData.cardDateEnd }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-list-item :href="getMedtochkaSectionUrl(medtochkaRoutes.home)">
              <v-list-item-icon>
                <v-icon class="ui-icon-home-medlock" />
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title class="uiKitText--text">
                  Главная кабинета
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item :href="getMedtochkaSectionUrl(medtochkaRoutes.appointments)">
              <v-list-item-icon>
                <v-icon class="ui-icon-calendar-date-and-clock" />
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title class="uiKitText--text">
                  Записи на приём
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item :href="getMedtochkaSectionUrl(medtochkaRoutes.rates)">
              <v-list-item-icon>
                <v-icon class="ui-icon-Reviews" />
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title class="uiKitText--text">
                  Отзывы
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item
              :href="getMedtochkaSectionUrl(medtochkaRoutes.favourites)"
            >
              <v-list-item-icon>
                <v-icon class="ui-icon-heart" />
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title class="uiKitText--text">
                  Избранное
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item :href="getMedtochkaSectionUrl(medtochkaRoutes.medcard)">
              <v-list-item-icon>
                <v-icon class="ui-icon-med-book" />
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>
                  Медкарта
                </v-list-item-title>
                <v-list-item-subtitle
                  v-if="medcardStatusText"
                  class="error--text"
                >
                  {{ medcardStatusText }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-list-item @click="handleLogout">
              <v-list-item-icon>
                <v-icon class="ui-icon-log-out" />
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title class="uiKitText--text">
                  Выйти
                </v-list-item-title>
                <v-list-item-subtitle class="uiKitTextInfo--text">
                  на всех устройствах
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </template>

          <v-divider
            v-if="isRealPatient || isAnonymous"
            inset
          />

          <v-list-item
            v-if="projects"
            data-qa="other_projects"
            @click="openOurProjectsDialog"
          >
            <v-list-item-icon>
              <v-icon class="ui-icon-more-projects" />
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title class="uiKitText--text">
                Наши проекты
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-navigation-drawer>

      <OurProjectsDialog
        v-if="projects"
        v-model="isActiveOurProjectsDialog"
        :projects="projects"
      />
    </div>
  </v-app>
</template>

<script>
import AuthService from 'services/AuthService'
import { emitOpenCardInfo } from 'www/global.js.blocks/common/club-card-info/functions'
import { EVENTS as DIALOG_EVENTS } from 'components/common/core/BaseDialog/constants'
import { EVENTS as SNACKBAR_EVENTS, ERROR as SNACKBAR_ERROR } from 'components/common/core/SnackbarNotification/constants'
import OurProjectsDialog from 'components/mobile/OurProjectsDialog/OurProjectsDialog'
import ChooseLoginType from 'components/common/mixins/ChooseLoginType'
import {
  dispatchCustomEventGlobally,
  getQueryParam,
  axiosClient,
  createDevNotice,
} from 'utils'
import {
  DIALOG_LOGOUT,
  SNACKBAR_TOWN_SELECTED,
  EVENTS as SIDE_MENU_EVENTS,
  MEDCARD_STATUS,
} from './constants'

export default {
  name: 'SideMenu',
  components: {
    OurProjectsDialog,
  },
  mixins: [ChooseLoginType],
  props: {
    town: {
      type: String,
      default: '',
    },
    townSelectUrl: {
      type: String,
      required: true,
    },
    projects: {
      type: Object,
      default: undefined,
    },
    personalAreaUrl: {
      type: String,
      default: '',
    },
  },
  data: () => ({
    isActive: false,
    isRealPatient: window.USER.isRealPatient,
    isAnonymous: window.USER.isAnonymous,
    medcardStatus: null,
    medtochkaRoutes: {
      home: '/',
      profile: '/profile',
      appointments: '/appointments',
      rates: '/rates',
      favourites: '/favourites',
      medcard: '/medcard',
    },
    isActiveOurProjectsDialog: false,
    clubData: window.USER.club,
    isTransitionEnd: true,
    isCanRemoveUserKey: false, // Позволяет менеджерам из ПДМобиля повторно оставлять отзывы с одного планшета
  }),
  computed: {
    medcardStatusText() {
      switch (this.medcardStatus) {
        case MEDCARD_STATUS.EXPIRED:
          return 'Заблокирована'
        case MEDCARD_STATUS.NEED_CONFIRMATION:
          return 'Подтвердите доступ'
        default:
          return null
      }
    },
  },
  watch: {
    isActive() {
      this.isTransitionEnd = false
    },
  },
  created() {
    window.addEventListener(SIDE_MENU_EVENTS.open, () => {
      this.isActive = !this.isActive
    })
  },
  mounted() {
    if (this.isRealPatient) {
      this.setupMedcardStatus()
    }

    const selectedTown = getQueryParam('selectedTown')

    if (selectedTown && document.referrer === window.location.origin + this.townSelectUrl) {
      dispatchCustomEventGlobally(SNACKBAR_EVENTS.open, {
        ...SNACKBAR_TOWN_SELECTED,
        message: SNACKBAR_TOWN_SELECTED.message + this.town,
        handleAction: () => {
          window.location.href = this.townSelectUrl
        },
      })
    }
  },
  methods: {
    async setupMedcardStatus() {
      try {
        const { data: { medcard_status: status } } = await axiosClient.get(`${window.MEDTOCHKA_URL}/api/me/`, {
          withCredentials: true,
        })

        this.medcardStatus = status
      } catch (e) {
        if ((e?.response?.status !== 401)) {
          createDevNotice({
            mode: 'error',
            module: 'SideMenu',
            method: 'setupMedcardStatus',
            description: e.message,
          })
        }
      }
    },
    openOurProjectsDialog() {
      this.isActiveOurProjectsDialog = true
    },
    getMedtochkaSectionUrl(route) {
      return `${window.MEDTOCHKA_URL}${route}`
    },
    handleLogout() {
      dispatchCustomEventGlobally(DIALOG_EVENTS.open, {
        ...DIALOG_LOGOUT,
        beforeConfirm: () => this.logout(),
      })
    },
    loginRelocation(url) {
      window.location.assign(url)
    },
    async logout() {
      try {
        await AuthService.logoutPd()
        window.location.assign('/')
      } catch {
        dispatchCustomEventGlobally(SNACKBAR_EVENTS.open, SNACKBAR_ERROR)
      }
    },
    handleClickCardInfo() {
      this.isActive = false
      emitOpenCardInfo()
    },
    handleTransitionend() {
      this.isTransitionEnd = true
    },
  },
}
</script>

<style lang="scss">
@import '~www/themes/doctors/common/mixins';

#app[data-side-menu] {
  @include v-app-reset;
}
</style>

<style lang="scss" scoped>
@import '~www/themes/doctors/common/vuetify-custom-reset';

.app-side-menu_active {
  z-index: 1000;
}

::v-deep {
  @include vuetify-custom-reset;
}
</style>
