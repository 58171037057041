import { differenceInYears, parse } from 'date-fns'
import declensionByNum from 'utils/executors/declensionByNum'
import { YEAR_CASES } from 'constants/wordCases'

/**
 * @description
 *
 * Функция возвращает объект для валидации поля birthDay
 *
 * @return { Object } - объект функций с валидацией поля по различным параметрам. Каждая функция принимает обязательное значение поля - value
 *
 * */

const MAX_USER_AGE = 150

const getBirthDayValidation = () => ({
  required: val => !!val || 'Укажите дату рождения в формате ДД.ММ.ГГГГ',
  ageLimit: ({ min, max } = {}) => val => {
    const now = new Date()
    const selectedDate = parse(val, 'dd.MM.yyyy', new Date())
    const age = differenceInYears(now, selectedDate)

    if (Number.isNaN(age) || age > MAX_USER_AGE || age < 0 || (now < selectedDate)) {
      return 'Проверьте дату рождения'
    }

    if (min && age < min) {
      const minYearsDeclension = declensionByNum(min, YEAR_CASES.genitive)

      return `Врач принимает только пациентов с ${min} ${minYearsDeclension}`
    }

    if (max && age > max) {
      const maxYearsDeclension = declensionByNum(max, YEAR_CASES.genitive)

      return `Врач принимает только пациентов до ${max} ${maxYearsDeclension}`
    }

    return true
  },
})

export default getBirthDayValidation
