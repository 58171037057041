<template>
  <component
    :is="wrapperComponent.el"
    v-model="state.isEnabledWidget"
    v-bind="wrapperComponent.props"
  >
    <VCard
      ref="club-card-getting-body"
      data-qa="club_card_getting"
      class="px-4 pt-6 pb-4"
    >
      <Confirmation
        v-if="state.step === steps.confirmation"
        :redirect-props="redirectData"
        v-on="{
          [events.userAuth.confirmed]: handleEventConfirmed,
          [events.club.cardGettingRedirectOrCancel]: handleEventRedirectOrCancel,
        }"
      />
      <Preloader
        v-else-if="state.step === steps.preloaderOnly || state.step === steps.preloaderText"
        :style-props="styleData"
        :use-text="state.step === steps.preloaderText"
      />
      <ClubEnter
        v-else-if="state.step === steps.clubEnter"
        :club-data="clubData"
        v-on="{
          [events.club.cardGettingCancel]: closeWidget,
          [events.club.cardGettingSubmit]: handleCardGettingSubmit,
        }"
      />
      <EmailEnter
        v-else-if="state.step === steps.emailEnter"
        v-on="{
          [events.club.cardGettingEmailSent]: handleEventEmailSent,
        }"
      />
      <PaymentMade
        v-else-if="state.step === steps.paymentMade"
        :club-data="clubData"
        :user-phone="userPhone"
        :component-props="paymentMadeData"
        v-on="{
          [events.club.cardGettingCancel]: closeWidget,
          [events.club.cardGettingRedirect]: redirectToNewAppointment,
        }"
      />
      <Error
        v-else-if="state.step === steps.error"
        :component-data="errorData"
        v-on="{
          [events.club.cardGettingErrorSubmit]: handleActionInError,
          [events.club.cardGettingErrorCancel]: handleActionInError,
        }"
      />
    </VCard>
  </component>
</template>

<script>
import { VBottomSheet, VDialog } from 'vuetify/lib'
import { fireConfetti } from 'components/common/AppointmentPage/functions'

import {
  Error,
  ClubEnter,
  Preloader,
  EmailEnter,
  PaymentMade,
  Confirmation,
} from 'components/common/Club/components/core/ClubCardGetting/components'

import {
  MainLogicMixin,
} from 'components/common/Club/components/core/ClubCardGetting/mixins'

export default {
  name: 'ClubCardGetting',
  components: {
    Error,
    VDialog,
    ClubEnter,
    Preloader,
    EmailEnter,
    PaymentMade,
    VBottomSheet,
    Confirmation,
  },
  mixins: [
    MainLogicMixin,
  ],
  data: () => ({
    isMobile: window.MOBILE_VERSION,
  }),
  computed: {
    wrapperComponent() {
      return this.isMobile
        ? {
          el: VBottomSheet,
          props: {
            persistent: true,
          },
        }
        : {
          el: VDialog,
          props: {
            width: 456,
            persistent: true,
          },
        }
    },
  },
  mounted() {
    if (this.showConfettiImage) {
      fireConfetti()
    }
  },
}
</script>
