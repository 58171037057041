const isCorrectValue = param => typeof param === 'string' && param

function getDoctorDescription({ experience, category, science }) {
  let description = ''

  if (isCorrectValue(experience)) {
    description += `Стаж ${experience}`
  }

  if (isCorrectValue(category)) {
    const categoryText = category.toLowerCase() === 'нет'
      ? 'Нет категории'
      : `${category} категория`

    description += description
      ? ` • ${categoryText}`
      : categoryText
  }

  if (isCorrectValue(science)) {
    const scienceText = science.toLowerCase() === 'нет'
      ? 'Нет степени'
      : `${science}`

    description += description
      ? `, ${scienceText.toLowerCase()}`
      : scienceText
  }

  return description
}

export default getDoctorDescription
