export const MOUNT_SELECTOR = '.club-appointment-notice-mount'
export const DATA_BTN_PHONE = 'data-club-appointment-notice'
export const INFO_OPTIONS = {
  callDoctor: 'callDoctor',
  clubIsCheaper: 'clubIsCheaper',
}

export const DATA_ATTRS = {
  wpBlockId: 'data-wp-block-id',
  timetableItem: 'data-timetable-item',
}
