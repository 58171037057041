import { getGoalsList } from 'utils'

const CLUB_QA_GOALS = getGoalsList({
  block: 'club',
  goals: [
    'nodeTimer', // узел таймера
    'nodeButtonPayment', // узел кнопки 'Оплатить'
    'nodePriceWithoutClub', // узел полной НЕ клубной цены
    'nodePriceClub', // узел полной клубной цены
    'nodePriceNow', // узел цены для оплаты сейчас
    'nodePriceLpu', // узел цены для оплаты в клинике
    'nodeEmailSendInput', // узел для ввода 'email'
    'nodeEmailSendButton', // узел кнопки 'Отправить email'
    'appointmentNoticeDiscount', // в компоненте "ClubAppointmentNotice" скидка на прием
    'appointmentNoticeClubPrice', // в компоненте "ClubAppointmentNotice" клубная цена
    'appointmentNoticeLpuPrice', // в компоненте "ClubAppointmentNotice" обычная цена
    'appointmentNoticeSavingPrice', // в компоненте "ClubAppointmentNotice" экономия
    'sliderTabLpu', // в компоненте "AppointmentTabList" обычный таб
    'sliderTabTelemed', // в компоненте "AppointmentTabList" телемед таб
    'sliderTabHome', // в компоненте "AppointmentTabList" таб "На дом"
  ],
})

export default CLUB_QA_GOALS
