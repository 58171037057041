import { getQueryParam } from 'utils'

/**
 * @description Возвращает id указанного GET-параметра.
 * Если указанный GET-параметр есть, а его значения нет, то возвращает null.
 *
 * @param { string } queryName - имя GET-параметра
 *
 * @return { number | null | undefined }
 * */
function getIdFromQuery(queryName) {
  const id = getQueryParam(queryName)

  if (id === null) {
    return undefined
  }

  const convertedId = Number(id)

  return id && Number.isInteger(convertedId)
    ? convertedId
    : null
}

export default getIdFromQuery
