import { format, parseISO } from 'date-fns'
import { ru } from 'date-fns/locale'

/**
 * Возвращает заголовок для сообщения экрана успеха, при записи на ЭЗ,
 * в случае когда клиника в данный момент не работает.
 * */
function getSuccessCallTimeTitle(dateString) {
  const formattedDate = format(parseISO(dateString), 'd MMMM в H:mm', { locale: ru })

  return `Ожидайте звонка ${formattedDate}`
}

export default getSuccessCallTimeTitle
