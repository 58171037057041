import { createDevNotice } from 'utils'

/**
 * @description
 * Возвращает результат сравнения возраста пациента с полями age места работы врача.
 * Если возраст пациента входит в диапазон age workplace - вернется результат сравнения.
 * Если передан некорректный возраст - вернется false.
 * Если передан некорректный workplace - вернется true, так как далеко не у всех врачей есть ограничение на возраст при приеме пациентов.
 * Если произошла ошибка в коде - catch вернет false.
 *
 * @param { Object } opts
 * @param { Object } opts.workplace - место работы
 * @param { Number } opts.ageNumberPatient - возраст пациента
 *
 * @return { Boolean }
 * */

function getHasSuitableAgeInWorkplace({ workplace = {}, ageNumberPatient }) {
  try {
    if (
      typeof ageNumberPatient !== 'number'
      || Number.isNaN(ageNumberPatient)
    ) {
      return false
    }

    if (!workplace?.age) {
      return true
    }

    const { min, max } = workplace.age
    const hasMinAge = typeof min === 'number' && !Number.isNaN(min)
    const hasMaxAge = typeof max === 'number' && !Number.isNaN(max)

    if (hasMinAge && hasMaxAge) {
      return ageNumberPatient >= workplace.age.min && ageNumberPatient <= workplace.age.max
    }

    if (hasMinAge) {
      return ageNumberPatient >= workplace.age.min
    }

    if (hasMaxAge) {
      return ageNumberPatient <= workplace.age.max
    }

    return true
  } catch ({ message }) {
    createDevNotice({
      module: 'Function: getHasSuitableAgeInWorkplace',
      description: message,
    })

    return false
  }
}

export default getHasSuitableAgeInWorkplace
