import { format, addDays, isValid } from 'date-fns'
import { ru } from 'date-fns/locale'
import checkAvailableSlotBySchedule from 'components/common/AppointmentPage/functions/checkAvailableSlotBySchedule'

/**
 * @typedef LpuScheduleDay
 * @property { string } timeStart
 * @property { string } timeEnd
 * */

/**
 * @typedef CalendarSlot
 * @property { number } duration
 * @property { boolean } free
 * @property { string } time
 * */

/**
 * @description Ищет доступный слот по расписанию работы клиники
 *
 * @param { object } params
 * @param { string } params.day - день календаря
 * @param { CalendarSlot[] } params.slots - слоты календаря
 * @param { Record<string, LpuScheduleDay> } params.schedule - расписание работы клиники
 * @param { Date } [params.minDate] - минимальная дата, с которой нужно искать доступный слот
 *
 * @return { undefined | null | CalendarSlot }
 * */
function findAvailableSlotBySchedule({
  day,
  slots,
  schedule,
  minDate,
}) {
  if (typeof day !== 'string') {
    return null
  }

  const dayDate = new Date(day)

  if (!isValid(dayDate)) {
    return null
  }

  const prevDay = format(
    addDays(dayDate, -1),
    'yyyy-MM-dd',
    { locale: ru },
  )
  const scheduleDay = schedule[day]
  const schedulePrevDay = schedule[prevDay]

  if (!scheduleDay && !schedulePrevDay) {
    return null
  }

  return slots.find(({ time }) => {
    const slotDate = new Date(`${day}T${time}`)

    if (minDate && slotDate <= minDate) {
      return false
    }

    return checkAvailableSlotBySchedule({
      slotDay: day,
      slotPrevDay: prevDay,
      slotDate,
      scheduleDay,
      schedule,
    })
  })
}

export default findAvailableSlotBySchedule
