var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { "data-qa": "user_authorization" } },
    [
      _c("UserAuthorizationForm", {
        ref: "authorization-form",
        attrs: {
          "fn-handler-submit-form": _vm.handleGetCode,
          "hook-before-submit-form": _vm.hookBeforeSubmitForm,
          "is-phone-confirmed": _vm.isPhoneConfirmed,
          "is-phone-readonly": _vm.isPhoneReadonly,
          "is-autofocus-field": _vm.isRenderInModal,
          "is-allowed-submit": !_vm.isDisabledButton,
          "is-blocking-error": _vm.isBlockingError,
          "init-phone": _vm.initPhone,
          "error-message": _vm.requestGetCodeData.errorMessage,
          "is-country-authorization": _vm.isCountryAuthorization,
          "is-authorization-vk": _vm.isAuthorizationVk,
          "is-default-slot-always-visible": "",
        },
        on: {
          "form-authorization:click-edit": _vm.handleClickEditPhone,
          "form-authorization:input": _vm.handleInputEditPhone,
        },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function ({ isActiveSlot, isCodeInputVisible }) {
              return [
                _vm.isVisibleCheckCode || _vm.isAuthorizationVk
                  ? _c("UserAuthorizationCheckCode", {
                      ref: "user-authorization-check-code",
                      attrs: {
                        phone: _vm.requestGetCodeData.phone,
                        "is-autofocus-field": isActiveSlot,
                        "confirmation-methods": _vm.confirmationMethods,
                        "is-no-registration": _vm.isNoRegistration,
                        "service-id": _vm.requestGetCodeData.serviceId,
                        "is-code-input-visible":
                          (_vm.isVisibleCheckCode && isCodeInputVisible) ||
                          !_vm.isAuthorizationVk,
                        "data-qa": "user_authorization_code_confirmation_help",
                      },
                      on: {
                        "authorization-check-code:success":
                          _vm.handleSuccessCheckCode,
                      },
                    })
                  : _vm._e(),
              ]
            },
          },
          {
            key: "action",
            fn: function ({ onSubmitForm, loading, disabled }) {
              return [
                _c("VExpandTransition", [
                  _vm.isVisibleErrorRequestExceeded
                    ? _c("div", [
                        _c(
                          "div",
                          {
                            staticClass:
                              "ui-text ui-text_body-2 ui-kit-color-text-secondary text-center mb-2",
                            attrs: {
                              "data-qa":
                                "user_authorization_code_exceeded_request_error",
                            },
                          },
                          [
                            _vm._v(
                              "\n            Подтвердить этот номер можно через сутки."
                            ),
                            _c("br"),
                            _vm._v(
                              "\n            Было слишком много попыток\n          "
                            ),
                          ]
                        ),
                      ])
                    : _vm.requestGetCodeData.isDeletingInProgress
                    ? _c(
                        "div",
                        [
                          _c(
                            "BaseAlert",
                            {
                              attrs: {
                                type: "red",
                                icon: "ui-icon-circle-warning",
                                "data-qa":
                                  "user_authorization_code_deleting_in_progress_error",
                              },
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "ui-text ui-text_body-2" },
                                [
                                  _vm._v(
                                    "\n              С этого номера пока нельзя записаться на приём или оставить отзыв:\n              старый профиль ещё на удалении, это займёт до 30 дней.\n            "
                                  ),
                                ]
                              ),
                            ]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  { style: _vm.styleForButton },
                  [
                    _c(
                      "VExpandTransition",
                      [
                        _vm.isVisibleButton
                          ? _c(
                              "VBtn",
                              {
                                staticClass: "pa-2",
                                attrs: {
                                  block: "",
                                  depressed: "",
                                  color: "primary",
                                  text: !_vm.isVisibleDefaultButton,
                                  loading: loading,
                                  disabled:
                                    _vm.extendDisabledForTimer(disabled),
                                  "data-qa":
                                    "user_authorization_confirmation_btn",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.handleClickConfirm(onSubmitForm)
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  "\n            " +
                                    _vm._s(_vm.buttonText) +
                                    " "
                                ),
                                _c("span", {
                                  attrs: {
                                    id: _vm.expireTime.mountElementId,
                                    hidden: !_vm.isVisibleExpireTime(disabled),
                                  },
                                }),
                              ]
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "VExpandTransition",
                      [
                        _vm.isSwitchTypeButtonVisible
                          ? _c(
                              "VBtn",
                              {
                                staticClass: "mt-2",
                                attrs: {
                                  "data-qa": "user_authorization_switch_type",
                                  color: "primary",
                                  disabled:
                                    _vm.extendDisabledForTimer(disabled),
                                  depressed: "",
                                  block: "",
                                  text: "",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.handleClickSwitchType(
                                      onSubmitForm
                                    )
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  "\n            " +
                                    _vm._s(
                                      _vm.CONFIRMATION_BUTTON_STATUSES
                                        .callOrSmsConfirm
                                    ) +
                                    "\n          "
                                ),
                              ]
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            },
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }