export const REQUEST_TYPES = {
  lpu: 'lpu',
  doctor: 'doctor',
  services: 'services',
}

export const REQUEST_ARRAY_NAME = 'services'

export const EVENTS_NAMES = {
  fakeTimeSelected: 'fake-time:selected',
  lpuSelectSpecialityClickOption: 'lpu-select-speciality:click-option',
}

export const NAMESPACE = {
  common: {
    selectorAppointmentTypeTab: '.appointment-tab-list',
  },
  dataAttrs: {
    activeTabValue: 'data-active-tab-value',
  },
}
