import { createDevNotice, dispatchCustomEventGlobally } from 'utils'
import { showCommonErrorMessage } from 'components/mobile/PhoneConfirmation/functions'
import { EVENTS as DIALOG_EVENTS } from 'components/common/core/BaseDialog/constants'
import {
  requestGetCodeForUser,
  requestGetCodeForStaff,
  requestGetCodeForUserNoRegistration,
} from 'components/common/UserAuthorization/api'
import {
  AUTH_ERRORS,
  CONFIRMATION_METHODS,
  DIALOG_ERROR_NETWORK_CONFIG,
} from 'components/common/UserAuthorization/constants'
import sendYaGoal from 'components/common/UserAuthorization/functions/sendYaGoal'
import { AUTHORIZATION_VK_GOALS } from 'yandexGoals'

/**
 * В данном миксине происходит обработка всех запросов на
 * отправку кода (из СМС или 4 последние цифры номера входящего звонка).
 * */

const authByCallOrSmsGetCodeMixin = {
  props: {
    isNoRegistration: {
      type: Boolean,
      default: false,
    },
  },
  data: vm => ({
    requestGetCodeData: {
      phone: '',
      isRequested: false, // если true, значит был произведён запрос минимум 1 раз на указанный телефон
      isAttemptsExceeded: false, // если true, значит попытки подтверждения текущим методом call/sms были исчерпаны
      isRequestExceeded: false, // если true, значит исчерпаны ежедневные попытки подтвердить указанный телефон
      isDeletingInProgress: false, // если true, значит старый профиль на удалении, поэтому записаться нельзя
      isWaitingTime: false, // если true, значит недавно уже был запрос, поэтому нужно подождать
      errorMessage: '',
      serviceId: '',
      isVkIgnored: false,
    },
    confirmationMethods: {
      isVk: !vm.isNoRegistration,
      isCall: false,
      isSms: vm.isNoRegistration || window.USER.isStaff,
    },
  }),
  computed: {
    isBlockingError() {
      return this.requestGetCodeData.isWaitingTime
        || this.requestGetCodeData.isDeletingInProgress
    },
  },
  methods: {
    resetBlockingErrors() {
      if (!this.isBlockingError) {
        return
      }

      this.requestGetCodeData.isWaitingTime = false
      this.requestGetCodeData.isDeletingInProgress = false
    },
    getIsRequestExceeded(errorEvent) {
      const { response } = errorEvent || {}
      const errorData = response?.data || {}
      const errorCode = errorData.code || errorData.phone?.code

      return typeof errorData.waiting_time !== 'number'
        && (errorCode === 'error_limit' || errorCode === 'error_limit_total')
    },

    handleGetCode({ phone }) {
      this.requestGetCodeData.isRequestExceeded = false

      return new Promise((resolve, reject) => {
        this.$refs['user-authorization-check-code'].reset()
        this.resetTimer()

        this.requestGetCodeData.phone = phone

        if (this.isNoRegistration) {
          requestGetCodeForUserNoRegistration(phone)
            .then(() => {
              this.handleSuccessGetCodeForUserNoRegistration()
              resolve()
            })
            .catch(errorEvent => {
              this.requestGetCodeData.isRequestExceeded = this.getIsRequestExceeded(errorEvent)

              /**
               * Если исчерпаны попытки запросить код, то обрабатываем это как
               * успешный запрос и отображаем поле с кодом, чтобы у пользователя
               * была возможность ввести старый код, который мог прийти с задержкой.
               * */
              if (this.requestGetCodeData.isRequestExceeded) {
                this.handleSuccessGetCodeForUserNoRegistration()
                resolve()

                return
              }

              this.handleErrorGetCodeForUserNoRegistration(errorEvent)
              reject()
            })
          return
        }

        if (window.USER.isStaff) {
          requestGetCodeForStaff(phone.slice(1))
            .then(response => {
              if (AUTH_ERRORS[response?.data]) {
                this.handleErrorGetCodeForStaff({ response })

                reject()

                return
              }

              this.handleSuccessGetCodeForStaff()
              resolve()
            })
            .catch(errorEvent => {
              this.handleErrorGetCodeForStaff(errorEvent)
              reject()
            })
          return
        }

        const isVkFlow = this.confirmationMethods.isVk
          && !this.requestGetCodeData.isVkIgnored
          && !this.requestGetCodeData.isAttemptsExceeded

        requestGetCodeForUser({ phone, isVkFlow })
          .then(response => {
            this.handleSuccessGetCodeForUser(response)
            resolve()
          })
          .catch(errorEvent => {
            this.requestGetCodeData.isRequestExceeded = this.getIsRequestExceeded(errorEvent)

            /**
             * Если исчерпаны попытки запросить код, то обрабатываем это как
             * успешный запрос и отображаем поле с кодом, чтобы у пользователя
             * была возможность ввести старый код, который мог прийти с задержкой.
             * */
            if (
              this.requestGetCodeData.isRequestExceeded
              && (!this.confirmationMethods.isVk || this.requestGetCodeData.isVkIgnored)
            ) {
              this.handleSuccessGetCodeForUser()
              resolve()

              return
            }

            this.handleErrorGetCodeForUser(errorEvent)
            reject()
          })
      })
    },

    /**
     * NoRegistration
     *
     * Т.к. при подтверждении звонком автоматически создаётся профиль в МедТочке,
     * то для регистрации врача/клиники используем подтверждение через СМС
     * */
    handleSuccessGetCodeForUserNoRegistration() {
      this.requestGetCodeData.isRequested = true

      this.$emit('authorization:requested')
      this.initTimer()
    },
    handleErrorGetCodeForUserNoRegistration({ response, message } = {}) {
      this.requestGetCodeData.isRequested = false

      const errorData = response?.data
      const errorCode = errorData?.phone?.code

      if (AUTH_ERRORS[errorCode]) {
        this.requestGetCodeData.errorMessage = AUTH_ERRORS[errorCode]
        return
      }

      if (!window.navigator.onLine || message === 'Network Error') {
        dispatchCustomEventGlobally(DIALOG_EVENTS.open, DIALOG_ERROR_NETWORK_CONFIG)
        return
      }

      showCommonErrorMessage()

      createDevNotice({
        module: 'authByCallOrSmsGetCodeMixin.js',
        method: 'handleErrorGetCodeForUserNoRegistration',
        description: `Ошибка авторизации. ${JSON.stringify(errorData)}`,
      })
    },

    /**
     * Staff
     * */
    handleSuccessGetCodeForStaff() {
      this.requestGetCodeData.isRequested = true

      this.$emit('authorization:requested')
      this.initTimer()
    },
    handleErrorGetCodeForStaff({ response, message } = {}) {
      this.requestGetCodeData.isRequested = false

      const errorData = response?.data

      if (!window.navigator.onLine || message === 'Network Error') {
        dispatchCustomEventGlobally(DIALOG_EVENTS.open, DIALOG_ERROR_NETWORK_CONFIG)
        return
      }

      if (AUTH_ERRORS[errorData]) {
        this.requestGetCodeData.errorMessage = AUTH_ERRORS[errorData]
        return
      }

      showCommonErrorMessage()

      createDevNotice({
        module: 'authByCallOrSmsGetCodeMixin.js',
        method: 'handleErrorGetCodeForStaff',
        description: `Ошибка авторизации. ${JSON.stringify(errorData)}`,
      })
    },

    /**
     * User
     * */
    handleSuccessGetCodeForUser(response) {
      this.requestGetCodeData.isRequested = true

      this.$emit('authorization:requested')
      this.initTimer()

      if (!response) {
        return
      }

      const {
        code,
        service_id: serviceId,
        attempts_exceeded: attemptsExceeded,
      } = response.data || {}

      this.confirmationMethods = {
        isCall: code === CONFIRMATION_METHODS.call,
        isSms: code === CONFIRMATION_METHODS.sms,
        isVk: code === CONFIRMATION_METHODS.vk,
      }

      this.requestGetCodeData.serviceId = serviceId
      this.requestGetCodeData.isAttemptsExceeded = attemptsExceeded
    },
    handleErrorGetCodeForUser({ response, message } = {}) {
      this.requestGetCodeData.isRequested = false

      const errorData = response?.data
      const errorCode = errorData?.code || errorData?.phone?.code

      if (errorCode === 'error_limit' && typeof errorData.waiting_time === 'number') {
        this.handleErrorExpireTime(errorData.waiting_time)
        return
      }

      if (errorCode === 'deleting_in_progress') {
        this.requestGetCodeData.isDeletingInProgress = true
        return
      }

      if (errorCode === 'error_limit' && this.confirmationMethods.isVk) {
        this.requestGetCodeData.isRequestExceeded = false
        dispatchCustomEventGlobally(DIALOG_EVENTS.open, {
          maxWidth: 304,
          title: 'Не получается подтвердить телефон через ВКонтакте',
          text: 'Закончились попытки. Можно подтвердить через\u00A0звонок или\u00A0СМС.',
          closeText: 'Закрыть',
          confirmText: 'Подтвердить',
          persistent: true,
          beforeConfirm: () => {
            this.requestGetCodeData.isVkIgnored = true
            this.$refs['authorization-form'].onSubmitForm()
            sendYaGoal(AUTHORIZATION_VK_GOALS.limitErrorConfirm)
          },
        })
        return
      }

      if (AUTH_ERRORS[errorCode]) {
        this.requestGetCodeData.errorMessage = AUTH_ERRORS[errorCode]
        return
      }

      if (!window.navigator.onLine || message === 'Network Error') {
        dispatchCustomEventGlobally(DIALOG_EVENTS.open, DIALOG_ERROR_NETWORK_CONFIG)
        return
      }

      showCommonErrorMessage()

      createDevNotice({
        module: 'authByCallOrSmsGetCodeMixin.js',
        method: 'handleErrorGetCodeForUser',
        description: `Ошибка авторизации. ${JSON.stringify(errorData)}`,
      })
    },
  },
}

export default authByCallOrSmsGetCodeMixin
