import { REGEX } from 'utils'
import { MAX_FIO_LENGTH } from 'components/common/AppointmentPage/constants'

/**
 * @callback Rule
 * @param { any } val
 *
 * @return { true | string }
 * */
/** @typedef { Rule } OnlyRuRule */
/** @typedef { Rule } MaxLengthRule */
/** @typedef { Rule } RequiredRule */

/**
 * @param { string } requiredDescription
 *
 * @return { { onlyRu: OnlyRuRule, maxLength: MaxLengthRule, required: RequiredRule } }
 * */
const getFioValidationRules = requiredDescription => {
  /** @type { OnlyRuRule } */
  const onlyRu = val => REGEX.fio.test(val) || 'Используйте только кириллицу'
  /** @type { MaxLengthRule } */
  const maxLength = val => val.length <= MAX_FIO_LENGTH || `Должно быть не больше ${MAX_FIO_LENGTH} символов`
  /** @type { RequiredRule } */
  const required = val => !!val || requiredDescription

  return { onlyRu, maxLength, required }
}

export default getFioValidationRules
