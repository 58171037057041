import { APPOINTMENT_TYPES } from 'constants'
import {
  DATA_ATTRS,
  INFO_OPTIONS,
  DATA_BTN_PHONE,
} from 'components/common/Club/components/dependencies/ClubAppointmentNotice/constants'
import { CLUB_EVENTS } from 'components/common/Club/constants'
import { REQUEST_TYPES } from 'modules/Timetable/constants'
import { getVisitType } from 'modules/Timetable/functions'
import {
  getDataJSON,
  getOnlyNumbers,
  transformPropsToNumber,
  transformPropsToBoolean,
  dispatchCustomEventGlobally,
} from 'utils'

function transformData(data = {}) {
  data.phoneNumber = getOnlyNumbers(data.phoneNumber)

  transformPropsToBoolean(data, ['isClub'])
  transformPropsToNumber(data, ['clubDiscount', 'priceFull', 'price'])

  return data
}

function setListenerPhoneClick() {
  document.addEventListener('click', event => {
    const { target } = event
    const buttonPhoneElement = target.closest(`[${DATA_BTN_PHONE}]`)

    if (!buttonPhoneElement) {
      return
    }

    const doctorRawData = getDataJSON({
      dataName: DATA_BTN_PHONE,
      container: buttonPhoneElement,
    })
    const doctorData = transformData(doctorRawData[0]?.data)
    const visitType = getVisitType({
      target: event.target,
      options: {
        core: {
          typeRequest: REQUEST_TYPES.doctor,
          namespace: {
            dataItem: document.querySelector(`[${DATA_ATTRS.wpBlockId}]`) ? DATA_ATTRS.wpBlockId : DATA_ATTRS.timetableItem,
          },
        },
      },
    })

    if (!doctorData.isClub) {
      return
    }

    if (doctorData.infoOption === INFO_OPTIONS.callDoctor) {
      if (
        visitType === APPOINTMENT_TYPES.online
                || visitType === APPOINTMENT_TYPES.telemed
                || visitType === APPOINTMENT_TYPES.home
      ) {
        return
      }
    }

    event.preventDefault()
    dispatchCustomEventGlobally(CLUB_EVENTS.clubClickShowNotice, doctorData)
  })
}

export { setListenerPhoneClick }
